<template>
  <div v-if="allPageParamsSet&&currentPageNo>=1">
    <div v-if="isDebug" class="debug_str">TAMA/ExamInternalHeaderA1 currentPageNo={{ currentPageNo }}</div>

    <!-- 編集画面（およびデバッグ時の試験画面）用 ※こちらは普通の構造 -->
    <div v-if="isEditPage||(isExamPage&&isDebug)" class="row">
      <div class="col-12 px-2 px-sm-3 pager_wrapper d-flex flex-wrap">
        <div v-for="p in examinationPart.page_count" :key="`page_${p}`"
              class="pager_button_wrapper"
              :class="{ 'selected' : p == currentPageNo }"
              @click.prevent="onMovePage(p)"
        >
          <span class="pager_button my_18px">{{ p }}</span>
        </div>
      </div>
    </div>

    <!-- 結果画面（解説画面）用 ※これが特殊な構造 -->
    <!--
      問題画面は全部で36ページあり、通常なら１：１で解説画面も36ページとなるのに対し、
      本検査は「問題画面：解説画面＝４：１」となっていて、解説は9ページしかない。
      実際のデータ構造は解説も36ページ分あるが、4n+1ページ（n=0～8）しか意味のある中身を持っていない。
      36ページ分のデータをあたかも9ページに見せかける為に、専用のExamInternalHeaderおよびExamInternalFooterを用意した。
     -->
    <div v-else-if="isResultPage" class="row">
      <div class="col-12 px-2 px-sm-3 pager_wrapper d-flex flex-wrap">
        <template v-for="p in examinationPart.page_count">
          <div v-if="p%4==1" :key="`page_${p}`"
                              class="pager_button_wrapper wide"
                              :class="{ 'selected' : p == currentPageNo }"
                              @click.prevent="onMovePage(p)"
          >
            <span class="pager_button my_18px">{{ p }}～{{ p + 3 }}</span>
          </div>
        </template>
      </div>
    </div>

  </div>
</template>

<script>
const LogHeader = 'TAMA/ExamInternalHeaderA1';
export default {
  props: {
    canEdit: { default: false }, // どの画面から呼ばれたかの判定に用いている（管理画面or（試験画面or解説画面））
    showDescription: { default: false }, // どの画面から呼ばれたかの判定に用いている（（管理画面or解説画面）or試験画面）
    examinationPart: { default: null },
    currentPageNo: { default: null }, // 1-
    tabLabel: { default: 'page_no' }, // page_no or question_no ※現状、question_noには非対応
    isDebug: { default: false },
  },
  // data() {
  //   console.log(`[${LogHeader}] data() CALLED`);
  //   return {
  //   };
  // },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  // mounted() {
  //   console.log(`[${LogHeader}] mounted() CALLED`);
  // },
  computed: {
    isEditPage() { return this.canEdit },
    isExamPage() { return !this.canEdit && !this.showDescription },
    isResultPage() { return !this.canEdit && this.showDescription },
    allPageParamsSet() { return this.examinationPart? this.examinationPart.all_page_params_set: null; },
    useQNoAsLabel() { return this.tabLabel=='question_no' && !!this.allPageParamsSet; },
  },
  methods: {
    onMovePage(pageNo) {
      console.log(`[${LogHeader}] onMovePage(${pageNo}) -> emitting(move-page, ${pageNo})`);
      this.$emit('move-page', pageNo);
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../../../sass/_base"

$borderColor: #6F6F6F

.pager_wrapper
  border-bottom: 1px solid $borderColor
  .pager_button_wrapper
    &:not(.wide)
      width: 3.0em
    &.wide
      width: 6.0em
    line-height: 2.4em
    text-align: center
    &:hover
      cursor: pointer
      // text-decoration: underline
    &.selected
      border: 1px solid $borderColor
      // ↓ pager_wrapper のborder-bottomと重なった場合に、それを消すための設定
      border-bottom: 2px solid white
      margin-bottom: -1px
      // ↑
    .pager_button
      color: $colorTAMA
      font-weight: bold
</style>
