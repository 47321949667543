const LogHeader = 'personal_report/personal_report_base_2';

export default {
  props: {
    schoolExamStudent: { default: null },
    scExStudentExamPartSet: { default: null },
  },
  data() {
    // console.log(`[${LogHeader}] data() CALLED`);
    return {
    };
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  computed: {
    section2DataSet() { return this.scExStudentExamPartSet.personal_report_set['section_2']; },
  },
  methods: {
  },
};
