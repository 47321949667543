<template>
  <div v-if="examinationPart&&currentPageNo>=1">
    <div v-if="isDebug" class="debug_str">TG/ExamInternalFooter currentPageNo={{ currentPageNo }} examPartRemainingSec={{ examPartRemainingSec }}</div>

    <div class="row">
      <div class="col-12 d-flex flex-wrap justify-content-end align-items-center pb-3">

        <button class="btn btn-light_blue1 mx-3 my-1"
                :disabled="currentPageNo <= 1"
                @keydown.prevent
                @click.prevent="onMovePrevPage()"
        >
          &nbsp;前の画面へ&nbsp;
        </button>

        <button class="btn btn-light_blue1 mx-3 my-1"
                :disabled="isLastPage"
                @keydown.prevent
                @click.prevent="onMoveNextPage()"
        >
          &nbsp;次の画面へ&nbsp;
        </button>

      </div><!-- col -->
    </div><!-- row -->

    <template v-if="isEditPage||isExamPage">

      <div class="footer_border"></div>

      <div class="row mt-3">

        <div class="col-12 px-5 d-flex">
          <div style="width: 6em;">残り時間：</div>
          <div>{{ cnvSecondsToReadableStr(examPartRemainingSec) }}</div>
        </div>

      </div><!-- row -->

      <div class="row">
        <div class="col-12 d-flex flex-wrap justify-content-end">

          <button class="btn btn-orange1 mx-3 my-1"
                  :disabled="!isExamPage"
                  @keydown.prevent
                  @click.prevent="onFinishExamPart()"
          >
            &nbsp;&nbsp;&nbsp;すべてを送信して終了する&nbsp;&nbsp;&nbsp;
          </button>

        </div><!-- col -->
      </div><!-- row -->

    </template>

  </div>
</template>

<script>
const LogHeader = 'TG/ExamInternalFooter';
export default {
  props: {
    canEdit: { default: false }, // どの画面から呼ばれたかの判定に用いている（管理画面or（試験画面or解説画面））
    showDescription: { default: false }, // どの画面から呼ばれたかの判定に用いている（（管理画面or解説画面）or試験画面）
    examinationPart: { default: null },
    currentPageNo: { default: null }, // 1-
    examPartRemainingSec: { default: null }, // Result.vueからは渡されない
    questionCount: { default: null },
    answeredCount: { default: null },
    isDebug: { default: false },
  },
  // data() {
  //   console.log(`[${LogHeader}] data() CALLED`);
  //   return {
  //   };
  // },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  // mounted() {
  //   console.log(`[${LogHeader}] mounted() CALLED`);
  // },
  computed: {
    isLastPage() { return this.examinationPart? (this.currentPageNo == this.examinationPart.page_count ): false; },
    isEditPage() { return this.canEdit },
    isExamPage() { return !this.canEdit && !this.showDescription },
    isResultPage() { return !this.canEdit && this.showDescription },
  },
  methods: {
    cnvSecondsToReadableStr(sec) {
      if (sec < 0 || sec == null || sec == undefined) {
        sec = 0;
      }
      const onlySec = `00${sec % 60}`.slice(-2);
      const minutes = Math.floor(sec / 60);
      return `${minutes}分${onlySec}秒`;
    },
    onMovePrevPage() {
      console.log(`[${LogHeader}] onMovePrevPage() -> emitting(move-prev-page)`);
      this.$emit('move-prev-page');
    },
    onMoveNextPage() {
      console.log(`[${LogHeader}] onMoveNextPage() -> emitting(move-next-page)`);
      this.$emit('move-next-page');
    },
    onFinishExamPart() {
      console.log(`[${LogHeader}] onFinishExamPart() -> emitting(finish-exam-part)`);
      this.$emit('finish-exam-part');
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../../../sass/_base"

.footer_border
  border-bottom: 1px solid black
</style>
