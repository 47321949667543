<template>
  <div v-if="isAvailable&&examinationPart&&currentPageComponent">
    <div v-if="isDebug" class="debug_str">TG1 Ｂタイプ 検査２</div>

    <div class="row mt-3">
      <div class="col-12">
        <ExamInternalHeader :isDebug="isDebug"
                            :examinationPart="examinationPart" :currentPageNo="currentPageNo" :canEdit="canEdit" :showDescription="showDescription"
                            @move-page="onMovePage"
        />
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12" id="examination_parts_wrapper">
        <div v-if="isDebug" class="debug_str">ページのコンポーネント（{{ currentPageComponent }}）</div>
        <keep-alive>
          <component :is="currentPageComponent" ref="refPageComponent" :isDebug="isDebug"
                      :examinationPart="examinationPart" :canEdit="canEdit" :showDescription="showDescription"
                      choiceDelimiter="." :showLongAnswer="false"
                      v-model="currentPageParams"
          />
        </keep-alive>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <ExamInternalFooter :isDebug="isDebug"
                            :canEdit="canEdit" :showDescription="showDescription"
                            :examinationPart="examinationPart" :currentPageNo="currentPageNo" :examPartRemainingSec="examPartRemainingSec"
                            :questionCount="question_count" :answeredCount="answered_count"
                            @move-page="onMovePage" @move-next-page="onMoveNextPage" @move-prev-page="onMovePrevPage"
                            @finish-exam-part="onFinishExamPart"
        />
      </div>
    </div>

  </div>
</template>

<script>
import commonMixin from '../../common_exam_part';
import ExamInternalHeader from '../../TG/ExamInternalHeader';
import ExamInternalFooter from '../../TG/ExamInternalFooter';
// ↓ 本コンポーネントは以下のコンポーネントから呼ばれる
// admin/views/master/examination_parts/Edit.vue
// student/views/Exam.vue
// student/views/Result.vue
// ↑
// ↓ 配下の全ページをimportする ※ components への記載も忘れないこと！！
// ※ ページ部(_p01など)を除くコンポーネント名は examination_parts.main_component_name に定義されている
import tg1_b_t2_p01 from './検査２/p01';
import tg1_b_t2_p02 from './検査２/p02';
import tg1_b_t2_p03 from './検査２/p03';
import tg1_b_t2_p04 from './検査２/p04';
import tg1_b_t2_p05 from './検査２/p05';
import tg1_b_t2_p06 from './検査２/p06';
import tg1_b_t2_p07 from './検査２/p07';
import tg1_b_t2_p08 from './検査２/p08';
import tg1_b_t2_p09 from './検査２/p09';
import tg1_b_t2_p10 from './検査２/p10';
import tg1_b_t2_p11 from './検査２/p11';
import tg1_b_t2_p12 from './検査２/p12';
import tg1_b_t2_p13 from './検査２/p13';
import tg1_b_t2_p14 from './検査２/p14';
import tg1_b_t2_p15 from './検査２/p15';
import tg1_b_t2_p16 from './検査２/p16';
import tg1_b_t2_p17 from './検査２/p17';
import tg1_b_t2_p18 from './検査２/p18';
import tg1_b_t2_p19 from './検査２/p19';
import tg1_b_t2_p20 from './検査２/p20';
import tg1_b_t2_p21 from './検査２/p21';
import tg1_b_t2_p22 from './検査２/p22';
import tg1_b_t2_p23 from './検査２/p23';
import tg1_b_t2_p24 from './検査２/p24';
import tg1_b_t2_p25 from './検査２/p25';
import tg1_b_t2_p26 from './検査２/p26';
import tg1_b_t2_p27 from './検査２/p27';
import tg1_b_t2_p28 from './検査２/p28';
import tg1_b_t2_p29 from './検査２/p29';
import tg1_b_t2_p30 from './検査２/p30';
import tg1_b_t2_p31 from './検査２/p31';
import tg1_b_t2_p32 from './検査２/p32';
import tg1_b_t2_p33 from './検査２/p33';
import tg1_b_t2_p34 from './検査２/p34';
import tg1_b_t2_p35 from './検査２/p35';
import tg1_b_t2_p36 from './検査２/p36';
// ↑
const LogHeader = 'TG1/Ｂタイプ/検査２';

export default {
  mixins: [
    commonMixin,
  ],
  components: {
    ExamInternalHeader,
    ExamInternalFooter,
    tg1_b_t2_p01,
    tg1_b_t2_p02,
    tg1_b_t2_p03,
    tg1_b_t2_p04,
    tg1_b_t2_p05,
    tg1_b_t2_p06,
    tg1_b_t2_p07,
    tg1_b_t2_p08,
    tg1_b_t2_p09,
    tg1_b_t2_p10,
    tg1_b_t2_p11,
    tg1_b_t2_p12,
    tg1_b_t2_p13,
    tg1_b_t2_p14,
    tg1_b_t2_p15,
    tg1_b_t2_p16,
    tg1_b_t2_p17,
    tg1_b_t2_p18,
    tg1_b_t2_p19,
    tg1_b_t2_p20,
    tg1_b_t2_p21,
    tg1_b_t2_p22,
    tg1_b_t2_p23,
    tg1_b_t2_p24,
    tg1_b_t2_p25,
    tg1_b_t2_p26,
    tg1_b_t2_p27,
    tg1_b_t2_p28,
    tg1_b_t2_p29,
    tg1_b_t2_p30,
    tg1_b_t2_p31,
    tg1_b_t2_p32,
    tg1_b_t2_p33,
    tg1_b_t2_p34,
    tg1_b_t2_p35,
    tg1_b_t2_p36,
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  activated() {
    console.log(`[${LogHeader}] activated() CALLED`);
  },
  deactivated() {
    console.log(`[${LogHeader}] deactivated() CALLED`);
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
</style>
