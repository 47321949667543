const LogHeader = 'personal_report/personal_report_base';

export default {
  props: {
    schoolExamStudent: { default: null },
    scExStudentExamPartSet: { default: null },
  },
  data() {
    // console.log(`[${LogHeader}] data() CALLED`);
    return {
    };
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);

    // リサイズ検知用
    if (typeof this.onResize === 'function') {
      console.log(`[mixins/common] mounted() addEventListener(resize)`);
      window.addEventListener('resize', this.onResize);
      this.onResize(); // 呼び出し元で初期化できるように最初に１回呼んでおく
    }
  },
  destroyed() {
    console.log(`[${LogHeader}] destroyed() CALLED`);
    if (typeof this.onResize === 'function') {
      window.removeEventListener('resize', this.onResize);
    }
  },
  computed: {
    section11DataSet() { return this.scExStudentExamPartSet.personal_report_set['section_1']['section_1_1']; },
    section12DataSet() { return this.scExStudentExamPartSet.personal_report_set['section_1']['section_1_2']; },
    section13DataSet() { return this.scExStudentExamPartSet.personal_report_set['section_1']['section_1_3']; },
    section22DataSet() { return this.scExStudentExamPartSet.personal_report_set['section_2']['section_2_2']; },
    section23DataSet() { return this.scExStudentExamPartSet.personal_report_set['section_2']['section_2_3']; },
  },
  methods: {
    getCurrentGridClass() { // see also common/sass/_base.sass
      const width = window.innerWidth;
      let gridClass= 'xs'
      if (width >= 576) {
        gridClass = 'sm';
      }
      if (width >= 768) {
        gridClass = 'md';
      }
      if (width >= 992) {
        gridClass = 'lg';
      }
      if (width >= 1200) {
        gridClass = 'xl';
      }
      console.log(`[mixins/common] getCurrentGridClass() width=[${width}] → gridClass=[${gridClass}]`);
      return gridClass;
    },
  },
};
