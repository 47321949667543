<template>
  <div v-if="hasParams">
    <div v-if="isDebug" class="debug_str">TAMA1 Ｂタイプ 検査２ p08</div>

    <!-- ↓ 画面レイアウトタイプＦ ※choice_tableのような長いコードを含め、同一レイアウトでは同一コード -->

    <QuestionLayoutF :isDebug="isDebug" :canEdit="canEdit" :showDescription="showDescription"
                      :params="params" :questionIds="questionIds"
                      :descAnswers="showDescription? getDescAnswersStrArray(questionIds, choiceValues): []"
    >

      <template v-slot:page_s01>
        <MyTextBox :canEdit="canEdit" v-model="params.s01" />
      </template>

      <template v-for="questionId in questionIds" v-slot:[`q${params[questionId].q_no}_question`]>
        <MyTextBox :canEdit="canEdit" v-model="params[questionId].question" />
      </template>

      <template v-for="(questionId, questionIdx) in questionIds" v-slot:[`q${params[questionId].q_no}_choice_table`]>
        <div class="choice_table_cell" v-for="(choiceValue, choiceIdx) in choiceValues[questionIdx]" :key="choiceValue">
          <input type="checkbox" :id="`${questionId}_${choiceValue}`" class="choice_checkbox" :value="choiceValue" :disabled="!canAnswer"
                  v-model="params[questionId].answer"
                  :ref="`checkbox_q${questionId}`"
                  @click="onClickCheckbox(params[questionId], $refs[`checkbox_q${questionId}`], $event)"
                  @change="sortCheckboxAnswers(params[questionId])"
          />
          <label :for="`${questionId}_${choiceValue}`" class="choice_value_wrapper choice_checkbox_placeholder has_value">
            <div class="choice_value text-bold right_margin">{{ choiceValue }}{{ params[questionId].choices[choiceIdx] ? choiceDelimiter : '' }}</div>
          </label>
          <label :for="`${questionId}_${choiceValue}`" class="choice_caption_wrapper">
            <MyTextBox class="choice_caption text-bold" :canEdit="canEdit" v-model="params[questionId].choices[choiceIdx]" />
          </label>
        </div>
      </template>

      <template v-for="questionId in questionIds" v-slot:[`q${params[questionId].q_no}_description`]>
        <template v-if="showDescription">
          <MyTextBox :isTextarea="true" :canEdit="canEdit" v-model="params[questionId].description" />
        </template>
      </template>

    </QuestionLayoutF>

    <!-- ↑ 画面レイアウトタイプＦ -->

  </div>
</template>

<script>
import commonMixin from '../../../common_page';
const LogHeader = 'TAMA1/Ｂタイプ/検査２/p08';

export default {
  mixins: [
    commonMixin,
  ],
  data() {
    // console.log(`[${LogHeader}] data() CALLED`);
    return { // paramsを解析して自動生成することも可能だが、パフォーマンスと可読性を考慮してハードコーディングとした
      questionIds: [ 'q036', 'q037', 'q038', 'q039', 'q040' ],
      choiceValues: [
        [ 'A', 'B', 'C', 'D', 'E' ],
        [ 'A', 'B', 'C', 'D', 'E' ],
        [ 'A', 'B', 'C', 'D', 'E' ],
        [ 'A', 'B', 'C', 'D', 'E' ],
        [ 'A', 'B', 'C', 'D', 'E' ],
      ],
    };
  },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  // mounted() {
  //   console.log(`[${LogHeader}] mounted() CALLED`);
  // },
  activated() {
    console.log(`[${LogHeader}] activated() CALLED`);
  },
  deactivated() {
    console.log(`[${LogHeader}] deactivated() CALLED`);
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
</style>
