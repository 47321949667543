<template>
  <div v-if="hasParams">
    <div v-if="isDebug" class="debug_str">TAMA1 Ｃタイプ 検査２ p01</div>

    <!-- ↓ 画面レイアウトタイプＧ ※choice_tableのような長いコードを含め、同一レイアウトでは同一コード -->

    <QuestionLayoutG :isDebug="isDebug" :canEdit="canEdit" :showDescription="showDescription"
                      :params="params" :questionId="questionIds[0]"
                      :descAnswer="showDescription? getDescAnswerStr(params[questionIds[0]], choiceValues[0]) : ''"
    >

      <template v-slot:question_s01>
        <MyTextBox :canEdit="canEdit" v-model="params[questionIds[0]].s01" />
      </template>

      <template v-slot:question_s02>
        <MyTextBox :isTextarea="true" :canEdit="canEdit" v-model="params[questionIds[0]].s02" />
      </template>

      <template v-slot:question>
        <MyTextBox :canEdit="canEdit" v-model="params[questionIds[0]].question" />
      </template>

      <template v-slot:choice_table>
        <div class="choice_table_row" v-for="(choiceValue, choiceIdx) in choiceValues[0]" :key="choiceValue">
          <input type="checkbox" :id="`${questionIds[0]}_${choiceValue}`" class="choice_checkbox" :value="choiceValue" :disabled="!canAnswer"
                  v-model="params[questionIds[0]].answer"
                  :ref="`checkbox_q${questionIds[0]}`"
                  @click="onClickCheckbox(params[questionIds[0]], $refs[`checkbox_q${questionIds[0]}`], $event)"
                  @change="sortCheckboxAnswers(params[questionIds[0]])"
          />
          <label :for="`${questionIds[0]}_${choiceValue}`" class="choice_value_wrapper choice_checkbox_placeholder has_value">
            <div class="choice_value right_margin">{{ choiceValue }}{{ params[questionIds[0]].choices[choiceIdx] ? choiceDelimiter : '' }}</div>
          </label>
          <label :for="`${questionIds[0]}_${choiceValue}`" class="choice_caption_wrapper">
            <MyTextBox class="choice_caption" :canEdit="canEdit" v-model="params[questionIds[0]].choices[choiceIdx]" />
          </label>
        </div>
      </template>

      <template v-if="showDescription" v-slot:description>
        <MyTextBox :isTextarea="true" :canEdit="canEdit" v-model="params[questionIds[0]].description" />
      </template>

    </QuestionLayoutG>

    <!-- ↑ 画面レイアウトタイプＧ -->

  </div>
</template>

<script>
import commonMixin from '../../../common_page';
const LogHeader = 'TAMA1/Ｃタイプ/検査２/p01';

export default {
  mixins: [
    commonMixin,
  ],
  data() {
    // console.log(`[${LogHeader}] data() CALLED`);
    return { // paramsを解析して自動生成することも可能だが、パフォーマンスと可読性を考慮してハードコーディングとした
      questionIds: [ 'q001' ],
      choiceValues: [
        [ 'ア', 'イ', 'ウ', 'エ', 'オ' ],
      ],
    };
  },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  // mounted() {
  //   console.log(`[${LogHeader}] mounted() CALLED`);
  // },
  activated() {
    console.log(`[${LogHeader}] activated() CALLED`);
  },
  deactivated() {
    console.log(`[${LogHeader}] deactivated() CALLED`);
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
</style>
