import { render, staticRenderFns } from "./p12.vue?vue&type=template&id=2b169747&scoped=true&"
import script from "./p12.vue?vue&type=script&lang=js&"
export * from "./p12.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b169747",
  null
  
)

export default component.exports