<template>

  <div class="question_layout_f mt-3 mb-3">

    <div v-if="isDebug" class="debug_str">レイアウトタイプＦ</div>

    <!-- ↓ 問題文＆選択肢 ×Ｎ問 -->

    <div class="row">

      <div class="col-12 px-2 px-md-5 page_s01_wrapper">
        <div class="page_s01">
          <slot name="page_s01"></slot>
        </div>
      </div>

    </div>

    <div class="row mt-4" v-for="(n, idx) in questionCount" :key="`question_${n}`">

      <div class="col-12 px-2 px-md-3 question_wrapper">
        <div class="question_no">{{ questionNos[idx] }}</div>
        <div class="question text-bold my_18px">
          <slot :name="`q${questionNos[idx]}_question`"></slot>
        </div>
      </div>

      <div class="col-12 px-2 px-md-5 choice_table_wrapper">
        <div class="choice_table zebra">
          <slot :name="`q${questionNos[idx]}_choice_table`"></slot>
        </div>
      </div>

    </div>

    <!-- ↑ 問題文＆選択肢 ×Ｎ問 -->

    <!-- ↓ 解説 ×Ｎ問 -->
    <template v-if="showDescription">

      <hr class="mt-3" />

      <div class="row mt-2" v-for="(n, idx) in questionCount" :key="`description_${n}`">
        <div class="col-12 px-2 px-md-3 description_wrapper">
          <div class="answer_wrapper">
            <div class="question_no d-inline">{{ circledInt(questionNos[idx]) }}</div>
            <div class="desc_answer d-inline ml-1">正解&nbsp;{{ descAnswers[idx] }}</div>
          </div>
          <div class="description mt-1">
            <slot :name="`q${questionNos[idx]}_description`"></slot>
          </div>
        </div>
      </div>

    </template>
    <!-- ↑ 解説 ×Ｎ問 -->

  </div>

</template>

<script>
const LogHeader = 'examination_parts/QuestionLayoutF';
export default {
//   mixins: [
//   ],
  props: {
    canEdit: { default: false },
    showDescription: { default: false },
    isDebug: { default: false },
    params: { default: () => [] },
    questionIds: { default: () => [] },
    descAnswers: { default: () => [] },
  },
//   data() {
//     console.log(`[${LogHeader}] data() CALLED`);
//     return {
//     };
//   },
//   created() {
//     console.log(`[${LogHeader}] created() CALLED`);
//   },
//   mounted() {
//     console.log(`[${LogHeader}] mounted() CALLED`);
//   },
  computed: {
    questionCount() { return this.questionIds.length; },
    questionNos() {
      return this.questionIds.map((questionId) => {
        return this.params[questionId].q_no;
      });
    },
  },
  methods: {
    circledInt(num) { // 1-50の丸数字に対応
      if (1 <= num && num <= 20) {
        return String.fromCharCode('①'.charCodeAt(0) + num - 1);
      } else if (num <= 35) {
        return String.fromCharCode('㉑'.charCodeAt(0) + num - 21);
      } else if (num <= 50) {
        return String.fromCharCode('㊱'.charCodeAt(0) + num - 36);
      }
      return `${num}`;
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../../sass/_base"

.page_s01_wrapper
  //
  .page_s01
    //

.question_wrapper
  $questionNoMarginRight: 1.0em
  $questionNoWidth: 3.0em // １桁、２桁（、３桁）に対応できる幅が必要
  .question_no
    display: inline-block
    vertical-align: top
    margin-top: 0.25em
    margin-right: $questionNoMarginRight
    padding: 0.2em 0
    width: $questionNoWidth
    color: white
    background-color: black
    text-align: center
    // margin-bottom: 0.1em
  .question
    $questionPaddingX: 0.8em
    display: inline-block
    padding: 0.5em $questionPaddingX
    +mq(min_576)
      width: calc(100% - #{$questionNoWidth} - #{$questionNoMarginRight} - #{$questionPaddingX} * 2)
      max-width: 95%

.choice_table_wrapper // 横に「□ A 文字列」「□ B 文字列」・・・と並べるスタイル
  margin: 1.0em 0
  .choice_table
    display: flex
    flex-wrap: wrap
    .choice_table_cell
      label
        margin-bottom: 0 // 元々のマージンの打消し
      margin: 0.2em 0.5em
      padding: 0.2em 0.2em 0.2em 0.5em
      min-width: 8em
      // .choice_checkbox はcommon.sass参照
      .choice_value_wrapper
        .choice_value
          display: inline-block
          margin-left: 2.2em
          margin-right: 0.2em
          &.right_margin
            margin-right: 1.0em
          text-align: center
      .choice_caption_wrapper
        .choice_caption
          display: inline-block
    &.zebra .choice_table_cell:nth-of-type(odd)
      background-color: #EEEEEE
// .choice_table_wrapper

.description_wrapper
  .answer_wrapper
    .question_no
      // font-weight: bold
    .desc_answer
      // font-weight: bold
  .description
    //

</style>
