<template>

  <div class="question_layout_h mt-3 mb-3">

    <div v-if="isDebug" class="debug_str">レイアウトタイプＨ</div>
    <!-- タイプＣとタイプＨは類似（タイプＨは英文問題に最適化している） -->

    <!-- ↓ 問題文 -->

    <div class="row">

      <div class="col-12 px-2 px-md-5 page_s01_wrapper">
        <div class="page_s01">
          <slot name="page_s01"></slot>
        </div>
      </div>

      <div class="col-12 mt-3 px-2 px-md-5 page_s02_wrapper">
        <div class="page_s02 font-english">
          <slot name="page_s02"></slot>
        </div>
      </div>

    </div>

    <div class="row mt-5">

      <div class="col-12 px-2 px-md-5 question_wrapper">
        <div class="question_no">{{ questionNo }}</div>
        <div class="question">
          <slot name="question"></slot>
        </div>
      </div>

    </div>

    <!-- ↑ 問題文 -->

    <!-- ↓ 選択肢 -->
    <div class="row mt-4">
      <div class="col-12 px-2 px-md-5 choice_table_wrapper">
        <div class="choice_table">
          <slot name="choice_table"></slot>
        </div>
      </div>
    </div>
    <!-- ↑ 選択肢 -->

    <!-- ↓ 解説 -->
    <template v-if="showDescription">

      <hr class="mt-3" />

      <div class="row mt-5">
        <div class="col-12 px-2 px-md-5 description_wrapper">
          <div class="answer_wrapper">
            <div class="question_no d-inline">問{{ questionNo }}</div>
            <div class="desc_answer d-inline ml-3">正解{{ descAnswer }}</div>
          </div>
          <div class="description mt-4">
            <slot name="description"></slot>
          </div>
        </div>
      </div>

    </template>
    <!-- ↑ 解説 -->

  </div>

</template>

<script>
const LogHeader = 'examination_parts/QuestionLayoutH';
export default {
//   mixins: [
//   ],
  props: {
    canEdit: { default: false },
    showDescription: { default: false },
    isDebug: { default: false },
    params: { default: () => [] },
    questionId: { default: 0 },
    descAnswer: { default: '' },
  },
//   data() {
//     console.log(`[${LogHeader}] data() CALLED`);
//     return {
//     };
//   },
//   created() {
//     console.log(`[${LogHeader}] created() CALLED`);
//   },
//   mounted() {
//     console.log(`[${LogHeader}] mounted() CALLED`);
//   },
  computed: {
    questionNo() {
      return this.params[this.questionId].q_no;
    },
  },
//   methods: {
//   },
};
</script>

<style lang="sass" scoped>
@import "../../sass/_base"

.page_s01_wrapper
  //
  .page_s01
    max-width: 48em

.page_s02_wrapper
  //
  .page_s02
    max-width: 48em

.question_wrapper
  $questionNoMarginRight: 1.0em
  $questionNoWidth: 3.0em // １桁、２桁（、３桁）に対応できる幅が必要
  .question_no
    display: inline-block
    vertical-align: top
    margin-top: 0.25em
    margin-right: $questionNoMarginRight
    padding: 0.2em 0
    width: $questionNoWidth
    color: white
    background-color: black
    text-align: center
    // margin-bottom: 0.1em
  .question
    $questionPaddingX: 0.8em
    display: inline-block
    padding: 0.5em $questionPaddingX
    +mq(min_576)
      width: calc(100% - #{$questionNoWidth} - #{$questionNoMarginRight} - #{$questionPaddingX} * 2)
      max-width: 95%
    background-color: $colorLightYellow

.choice_table_wrapper // 縦に「□ A 長い文字列」「□ B 長い文字列」・・・と並べるスタイル
  margin: 1.0em 0
  .choice_table
    display: table
    width: 100%
    padding: 0 0.5em
    +mq(min_576)
      padding: 0.3em 1.0em
    .choice_table_row
      margin: 0.5em 0
      display: flex
      flex-wrap: wrap
      align-items: flex-start // flex-start or center
      // .choice_checkbox はcommon.sass参照
      .choice_value_wrapper
        .choice_value
          display: inline-block
          margin-left: 2.2em
          margin-right: 0.2em
          &.right_margin
            margin-right: 1.0em
          text-align: center
      .choice_caption_wrapper
        +mq(min_576)
          max-width: calc( 100% - 6em ) // 「□ A」部を除いたおおよその幅
        .choice_caption
          display: inline-block
// .choice_table_wrapper

.description_wrapper
  .answer_wrapper
    .question_no
      font-weight: bold
    .desc_answer
      font-weight: bold
  .description
    //

</style>
