<template>
  <div v-if="isAvailable&&examinationPart&&currentPageComponent">
    <div v-if="isDebug" class="debug_str">TG1 Ａタイプ 検査２</div>

    <div class="row mt-3">
      <div class="col-12">
        <ExamInternalHeader :isDebug="isDebug"
                            :examinationPart="examinationPart" :currentPageNo="currentPageNo" :canEdit="canEdit" :showDescription="showDescription"
                            @move-page="onMovePage"
        />
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12" id="examination_parts_wrapper">
        <div v-if="isDebug" class="debug_str">ページのコンポーネント（{{ currentPageComponent }}）</div>
        <keep-alive>
          <component :is="currentPageComponent" ref="refPageComponent" :isDebug="isDebug"
                      :examinationPart="examinationPart" :canEdit="canEdit" :showDescription="showDescription"
                      choiceDelimiter="." :showLongAnswer="false"
                      v-model="currentPageParams"
          />
        </keep-alive>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <ExamInternalFooter :isDebug="isDebug"
                            :canEdit="canEdit" :showDescription="showDescription"
                            :examinationPart="examinationPart" :currentPageNo="currentPageNo" :examPartRemainingSec="examPartRemainingSec"
                            :questionCount="question_count" :answeredCount="answered_count"
                            @move-page="onMovePage" @move-next-page="onMoveNextPage" @move-prev-page="onMovePrevPage"
                            @finish-exam-part="onFinishExamPart"
        />
      </div>
    </div>

  </div>
</template>

<script>
import commonMixin from '../../common_exam_part';
import ExamInternalHeader from '../../TG/ExamInternalHeader';
import ExamInternalFooter from '../../TG/ExamInternalFooter';
// ↓ 本コンポーネントは以下のコンポーネントから呼ばれる
// admin/views/master/examination_parts/Edit.vue
// student/views/Exam.vue
// student/views/Result.vue
// ↑
// ↓ 配下の全ページをimportする ※ components への記載も忘れないこと！！
// ※ ページ部(_p01など)を除くコンポーネント名は examination_parts.main_component_name に定義されている
import tg1_a_t2_p01 from './検査２/p01';
import tg1_a_t2_p02 from './検査２/p02';
import tg1_a_t2_p03 from './検査２/p03';
import tg1_a_t2_p04 from './検査２/p04';
import tg1_a_t2_p05 from './検査２/p05';
import tg1_a_t2_p06 from './検査２/p06';
import tg1_a_t2_p07 from './検査２/p07';
import tg1_a_t2_p08 from './検査２/p08';
import tg1_a_t2_p09 from './検査２/p09';
// ↑
const LogHeader = 'TG1/Ａタイプ/検査２';

export default {
  mixins: [
    commonMixin,
  ],
  components: {
    ExamInternalHeader,
    ExamInternalFooter,
    tg1_a_t2_p01,
    tg1_a_t2_p02,
    tg1_a_t2_p03,
    tg1_a_t2_p04,
    tg1_a_t2_p05,
    tg1_a_t2_p06,
    tg1_a_t2_p07,
    tg1_a_t2_p08,
    tg1_a_t2_p09,
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  activated() {
    console.log(`[${LogHeader}] activated() CALLED`);
  },
  deactivated() {
    console.log(`[${LogHeader}] deactivated() CALLED`);
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
</style>
