<template>

  <div class="question_layout_e mt-3 mb-3">

    <div v-if="isDebug" class="debug_str">レイアウトタイプＥ</div>
    <!-- タイプＥとタイプＩは類似かつ特殊（特にタイプＩ） -->

    <!-- ↓ 問題文 -->

    <div class="row">

      <div class="col-12 px-2 px-md-5 page_s01_wrapper">
        <div class="page_s01">
          <slot name="page_s01"></slot>
        </div>
      </div>

    </div>

    <div class="row mt-3">

      <div class="col-12 px-2 px-md-5 page_s02_wrapper">
        <div class="page_s02">
          <slot name="page_s02"></slot>
        </div>
      </div>

    </div>

    <div class="row mt-4 mb-5">

      <div class="col-12 px-2 px-md-5 page_s03_wrapper">
        <div class="page_s03">
          <slot name="page_s03"></slot>
        </div>
      </div>

    </div>

    <!-- ↑ 問題文 -->

    <!-- ↓ 編集画面、試験画面でのみ表示（＝結果画面では非表示） -->
    <template v-if="isEditPage||isExamPage">

      <!-- ↓ 問題文＆選択肢 × questionCount -->
      <div class="row mt-4" v-for="(n, idx) in questionCount" :key="`question_${n}`">

        <div class="col-12 px-2 px-md-5 question_wrapper">
          <div class="question_no">{{ questionNos[idx] }}</div>
          <div class="question">
            <slot :name="`q${questionNos[idx]}_question`"></slot>
          </div>
        </div>

        <div class="col-12 px-2 px-md-5 choice_table_wrapper">
          <div class="px-0 px-md-3 choice_table">
            <slot :name="`q${questionNos[idx]}_choice_table`"></slot>
          </div>
        </div>

      </div>
      <!-- ↑ 問題文＆選択肢 × questionCount -->

    </template>
    <!-- ↑ 編集画面、試験画面でのみ表示（＝結果画面では非表示） -->

    <!-- ↓ 結果画面でのみ表示（＝編集画面では非表示） -->
    <template v-if="isResultPage">

      <!-- ↓ 問題と受験者回答 × questionCount -->

      <div class="row no-gutters mt-4 ml-md-4 px-1 py-1 desc_questions_wrapper">
        <div class="col-12 desc_question_wrapper" v-for="(n, idx) in questionCount" :key="`desc_question_${n}`">
          <div class="question_no d-inline">【問{{ questionNos[idx] }}】</div>
          <div class="question d-inline">{{ questions[idx] }}</div>
        </div>
      </div>

      <div class="row mt-4 px-2 px-md-4 your_answers_wrapper">

        <div class="col-12 your_answers_caption_wrapper">
          <div class="your_answers_caption d-inline ml-md-3">あなたの解答</div>
        </div>

        <div class="col-12 your_answer_wrapper" v-for="(n, idx) in questionCount" :key="`your_answer_${n}`">
          <div class="question_no d-inline ml-md-3">【問{{ questionNos[idx] }}】</div>
          <div class="your_answer d-inline ml-1">{{ params[questionIds[idx]].answer.join('、') }}</div>
        </div>

      </div>

      <!-- ↑ 問題と受験者回答 × questionCount -->

    </template>
    <!-- ↑ 結果画面でのみ表示（＝編集画面では非表示） -->

    <!-- ↓ 編集画面、結果画面でのみ表示（＝試験画面では非表示） -->
    <template v-if="isEditPage||isResultPage">

      <hr v-if="isEditPage" class="mt-3" /><!-- 編集画面でのみ表示（＝結果画面では非表示） -->

      <!-- ↓ 解説 × questionCount -->
      <div class="row mt-4 px-2 px-md-4" v-for="(n, idx) in questionCount" :key="`description_${n}`">

        <div v-if="n==1" class="col-12 mb-1 desc_caption_wrapper">
          <div v-if="questionNos.length>1" class="desc_caption d-inline ml-md-3">解説&nbsp;【問{{ questionNos[0] }}】～【問{{ questionNos[questionNos.length-1] }}】</div>
          <div v-else class="desc_caption d-inline ml-md-3">解説</div>
        </div>

        <div class="col-12 description_wrapper">
          <div class="answer_wrapper">
            <div class="question_no d-inline ml-md-3">【問{{ questionNos[idx] }}】</div>
            <div class="desc_answer d-inline ml-1">{{ descAnswers[idx] }}</div>
          </div>
          <div class="description mt-1">
            <slot :name="`q${questionNos[idx]}_description`"></slot>
          </div>
        </div>

      </div>
      <!-- ↑ 解説 × questionCount -->

    </template>
    <!-- ↑ 編集画面、結果画面でのみ表示（＝試験画面では非表示） -->

  </div>

</template>

<script>
const LogHeader = 'examination_parts/QuestionLayoutE';
export default {
//   mixins: [
//   ],
  props: {
    canEdit: { default: false },
    showDescription: { default: false },
    isDebug: { default: false },
    params: { default: () => [] },
    questionIds: { default: () => [] },
    descAnswers: { default: () => [] },
  },
//   data() {
//     console.log(`[${LogHeader}] data() CALLED`);
//     return {
//     };
//   },
//   created() {
//     console.log(`[${LogHeader}] created() CALLED`);
//   },
//   mounted() {
//     console.log(`[${LogHeader}] mounted() CALLED`);
//   },
  computed: {
    isEditPage() { return this.canEdit },
    isExamPage() { return !this.canEdit && !this.showDescription },
    isResultPage() { return !this.canEdit && this.showDescription },
    questionCount() { return this.questionIds.length; },
    questionNos() {
      return this.questionIds.map((questionId) => {
        return this.params[questionId].q_no;
      });
    },
    questions() {
      return this.questionIds.map((questionId) => {
        return this.params[questionId].question;
      });
    },
  },
  // methods: {
  // },
};
</script>

<style lang="sass" scoped>
@import "../../sass/_base"

.page_s01_wrapper
  //
  .page_s01
    max-width: 48em

.page_s02_wrapper
  //
  .page_s02
    max-width: 48em

.page_s03_wrapper
  //
  .page_s03
    max-width: 48em

.question_wrapper
  $questionNoMarginRight: 1.0em
  $questionNoWidth: 3.0em // １桁、２桁（、３桁）に対応できる幅が必要
  .question_no
    display: inline-block
    vertical-align: top
    margin-top: 0.25em
    margin-right: $questionNoMarginRight
    padding: 0.2em 0
    width: $questionNoWidth
    color: white
    background-color: black
    text-align: center
    // margin-bottom: 0.1em
  .question
    $questionPaddingX: 0.8em
    display: inline-block
    padding: 0.5em $questionPaddingX
    +mq(min_576)
      width: calc(100% - #{$questionNoWidth} - #{$questionNoMarginRight} - #{$questionPaddingX} * 2)
      max-width: 95%
    background-color: $colorLightYellow

.choice_table_wrapper // 横に「□ A 文字列」「□ B 文字列」・・・と並べるスタイル
  margin: 1.0em
  .choice_table
    display: flex
    flex-wrap: wrap
    .choice_table_cell
      label
        margin-bottom: 0 // 元々のマージンの打消し
      margin: 0.2em 0.5em
      padding: 0.2em 0.2em 0.2em 0.5em
      min-width: 4em
      // .choice_checkbox はcommon.sass参照
      .choice_value_wrapper
        .choice_value
          display: inline-block
          margin-left: 2.2em
          margin-right: 0.2em
          &.right_margin
            margin-right: 1.0em
          text-align: center
      .choice_caption_wrapper
        .choice_caption
          display: inline-block
    &.zebra .choice_table_cell:nth-of-type(odd)
      background-color: #EEEEEE
// .choice_table_wrapper

.desc_questions_wrapper
  background-color: $colorLightYellow
  .desc_question_wrapper
    //
    .question_no
      //
    .question
      //

.your_answers_wrapper
  .your_answers_caption
    font-weight: bold
  .your_answer_wrapper
    //
    .question_no
      //
    .your_answer
      //

.desc_caption_wrapper
  .desc_caption
    font-weight: bold

.description_wrapper
  .answer_wrapper
    .question_no
      // font-weight: bold
    .desc_answer
      // font-weight: bold
  .description
    //

</style>
