<template>
  <div class="RD_report_wrapper">

    <!-- 2023-09現在、TGとTAMAでほぼ同一コードだが、デザイン変更に備えて共通コンポーネントではなく試験種別（ここ）に実装している -->

    <div class="RD_section_1">

      <div class="row RD_section_1_1">

        <div class="col-12 col-md-4 RD_section_1_title_wrapper">
          <div class="RD_section_1_title">あなたの成績</div>
        </div>

        <div class="col-12 col-md-8 RD_section_1_notes_wrapper">
          <div class="RD_section_1_note">・偏差値・順位は全国を基準としています。</div>
          <div class="RD_section_1_note">・ランクは偏差値を基準としてA〜Eのランクに分けています。</div>
        </div>

      </div><!-- row RD_section_1_1 -->

      <div class="row RD_section_1_2">

        <div class="col-12 col-md-4 RD_section_1_result_tables_outer_wrapper">

          <div class="row RD_section_1_result_tables_inner_wrapper">

            <div class="col-6 col-md-12 RD_section_1_result_table_wrapper">
              <div class="RD_section_1_result_table_caption">■総合</div>
              <table class="RD_section_1_result_table">
                <tr>
                  <th>偏差値</th>
                  <td>{{ section11DataSet.deviation_score }}</td>
                </tr>
                <tr>
                  <th>順位</th>
                  <td>{{ section11DataSet.rank }} / {{ rankMax }}</td>
                </tr>
                <tr>
                  <th>ランク</th>
                  <td>{{ section11DataSet.rank_char }}</td>
                </tr>
              </table>
            </div><!-- col RD_section_1_result_table_wrapper -->

            <div class="col-6 col-md-12 RD_section_1_result_table_wrapper">
              <div class="RD_section_1_result_table_caption">■{{ scExStudentExamPartSet.t2_is_english ? '英語' : '言語' }}</div>
              <table class="RD_section_1_result_table">
                <tr>
                  <th>偏差値</th>
                  <td>{{ section12DataSet.deviation_score }}</td>
                </tr>
                <tr>
                  <th>順位</th>
                  <td>{{ section12DataSet.rank }} / {{ rankMax }}</td>
                </tr>
                <tr>
                  <th>ランク</th>
                  <td>{{ section12DataSet.rank_char }}</td>
                </tr>
              </table>
            </div><!-- col RD_section_1_result_table_wrapper -->

            <div class="col-6 col-md-12 RD_section_1_result_table_wrapper">
              <div class="RD_section_1_result_table_caption">■計数</div>
              <table class="RD_section_1_result_table">
                <tr>
                  <th>偏差値</th>
                  <td>{{ section13DataSet.deviation_score }}</td>
                </tr>
                <tr>
                  <th>順位</th>
                  <td>{{ section13DataSet.rank }} / {{ rankMax }}</td>
                </tr>
                <tr>
                  <th>ランク</th>
                  <td>{{ section13DataSet.rank_char }}</td>
                </tr>
              </table>
            </div><!-- col RD_section_1_result_table_wrapper -->

          </div><!-- row RD_section_1_result_tables_inner_wrapper -->

        </div><!-- col RD_section_1_result_tables_outer_wrapper -->

        <div class="col-12 col-md-8 RD_section_1_svg_wrapper">
          <!-- see app/Examinations/XXX/AbstractExaminationService.php renderPersonalReportSection1() -->
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="RD_section_1_svg" viewBox="0 0 661.5 487.333">

            <!-- 外枠 -->
            <g fill="none" opacity="0.7">
              <path d="M 0 0 H 661.5 V 485.5 H 0 Z" stroke="none"/>
              <path d="M 0.5 0.5 L 0.5 485 L 661 485 L 661 0.5 L 0.5 0.5" stroke="gray" fill="none"/>
            </g>

            <!-- 説明文 -->
            <text transform="translate(53 55.333)" font-size="16" font-family="Meiryo"><tspan x="0" y="0">下記の表は、偏差値を基準としてA～Eランクに分けています。</tspan><tspan x="0" y="24">順位は全体の中であなたがどの位置にいるのかを表しています。</tspan></text>

            <g transform="translate(-557 -203.493)">

              <!-- 凡例 -->
              <g transform="translate(35 -361.5)"><rect width="31" height="24" transform="translate(682 672)" fill="#808cb4"/><rect width="31" height="24" transform="translate(820 672)" fill="#4ac11e"/><rect width="31" height="24" transform="translate(750 672)" fill="#3563f9"/><path d="M0,0H31V24H0Z" transform="translate(888 672)" fill="#ffd138"/><rect width="31" height="24" transform="translate(957 672)" fill="#d446f8"/><text transform="translate(722 689)" font-size="18" font-family="Meiryo-Bold, Meiryo" font-weight="700"><tspan x="0" y="0">E</tspan></text><text transform="translate(860 689)" font-size="18" font-family="Meiryo-Bold, Meiryo" font-weight="700"><tspan x="0" y="0">C</tspan></text><text transform="translate(790 689)" font-size="18" font-family="Meiryo-Bold, Meiryo" font-weight="700"><tspan x="0" y="0">D</tspan></text><text transform="translate(928 689)" font-size="18" font-family="Meiryo-Bold, Meiryo" font-weight="700"><tspan x="0" y="0">B</tspan></text><text transform="translate(997 689)" font-size="18" font-family="Meiryo-Bold, Meiryo" font-weight="700"><tspan x="0" y="0">A</tspan></text></g>

              <!-- 目盛り線 -->
              <defs><clipPath id="clip-path"><rect width="445" height="250" fill="none"/></clipPath></defs><g transform="translate(705 353.5)" clip-path="url(#clip-path)"><g transform="translate(-705 -353.5)"><line y2="250" transform="translate(705.5 353.5)" fill="none" stroke="#707070" stroke-width="1"/></g><g transform="translate(-618 -353.5)"><line y2="250" transform="translate(705.5 353.5)" fill="none" stroke="#707070" stroke-width="1"/></g><g transform="translate(-531 -353.5)"><line y2="250" transform="translate(705.5 353.5)" fill="none" stroke="#707070" stroke-width="1"/></g><g transform="translate(-444 -353.5)"><line y2="250" transform="translate(705.5 353.5)" fill="none" stroke="#707070" stroke-width="1"/></g><g transform="translate(-357 -353.5)"><line y2="250" transform="translate(705.5 353.5)" fill="none" stroke="#707070" stroke-width="1"/></g><g transform="translate(-270 -353.5)"><line y2="250" transform="translate(705.5 353.5)" fill="none" stroke="#707070" stroke-width="1"/></g></g>

              <!-- 各棒グラフ名の区切り線 -->
              <line x2="30" transform="translate(675.5 353.5)" fill="none" stroke="#707070" stroke-width="1"/><line x2="30" transform="translate(675.605 408.5)" fill="none" stroke="#707070" stroke-width="1"/><line x2="30" transform="translate(676 473.5)" fill="none" stroke="#707070" stroke-width="1"/><line x2="30" transform="translate(676 538.5)" fill="none" stroke="#707070" stroke-width="1"/><line x2="30" transform="translate(675 603.5)" fill="none" stroke="#707070" stroke-width="1"/>

              <!-- 目盛り文字列 -->
              <text transform="translate(682 633)" font-size="16" font-family="Meiryo"><tspan x="0" y="0">100%</tspan></text><text transform="translate(779 633)" font-size="16" font-family="Meiryo"><tspan x="0" y="0">80%</tspan></text><text transform="translate(866 633)" font-size="16" font-family="Meiryo"><tspan x="0" y="0">60%</tspan></text><text transform="translate(953 633)" font-size="16" font-family="Meiryo"><tspan x="0" y="0">40%</tspan></text><text transform="translate(1040 633)" font-size="16" font-family="Meiryo"><tspan x="0" y="0">20%</tspan></text><text transform="translate(1127 633)" font-size="16" font-family="Meiryo"><tspan x="0" y="0" xml:space="preserve"> 0%</tspan></text>

              <!-- 各棒グラフ名 -->
              <text transform="translate(607.5 387)" font-size="18" font-family="Meiryo"><tspan x="0" y="0">全国分布</tspan></text><text transform="translate(589.5 444)" font-size="18" font-family="Meiryo"><tspan x="0" y="0">順位（総合）</tspan></text><text transform="translate(589.5 514)" font-size="18" font-family="Meiryo"><tspan x="0" y="0">順位（{{ scExStudentExamPartSet.t2_is_english ? '英語' : '言語' }}）</tspan></text><text transform="translate(589.5 579)" font-size="18" font-family="Meiryo"><tspan x="0" y="0">順位（計数）</tspan></text>

              <!-- 背景 -->
              <rect width="48" height="251" transform="translate(705.145 353)" fill="#e6e2e2" opacity="0.25"/>
              <rect width="98" height="251" transform="translate(753.145 353)" fill="#e6e2e2" opacity="0.25"/>
              <path d="M0,0H130V251H0Z" transform="translate(851.145 353)" fill="#e6e2e2" opacity="0.25"/>
              <rect width="119" height="251" transform="translate(981.145 353)" fill="#e6e2e2" opacity="0.25"/>
              <path d="M0,0H41V251H0Z" transform="translate(1100.145 354)" fill="#e6e2e2" opacity="0.25"/>

              <!-- 全国分布 -->
              <rect v-for="(coords, idx) in nationalDistBarPlotCoords[0]" :key="`national_dist_bar_0_${idx}`" :width="coords.width" height="30" :transform="`translate(${coords.x} 366)`" :fill="coords.color"/>

              <!-- 順位（総合） -->
              <rect v-for="(coords, idx) in barPlotCoords[0]" :key="`bar_0_${idx}`" :width="coords.width" height="30" :transform="`translate(${coords.x} 426)`" :fill="coords.color"/>

              <!-- 順位（言語） -->
              <rect v-for="(coords, idx) in barPlotCoords[1]" :key="`bar_1_${idx}`" :width="coords.width" height="30" :transform="`translate(${coords.x} 491)`" :fill="coords.color"/>

              <!-- 順位（計数） -->
              <rect v-for="(coords, idx) in barPlotCoords[2]" :key="`bar_2_${idx}`" :width="coords.width" height="30" :transform="`translate(${coords.x} 556)`" :fill="coords.color"/>

            </g>
          </svg>
        </div><!-- col RD_section_1_svg_wrapper -->

      </div><!-- row RD_section_1_2 -->

    </div><!-- RD_section_1 -->

    <div class="RD_section_2">

      <div class="row RD_section_2_1">

        <div class="col-12 col-md-4 RD_section_2_title_wrapper">
          <div class="RD_section_2_title">正誤表</div>
        </div>

        <div class="col-12 col-md-8 RD_section_2_notes_wrapper">
          <div class="RD_section_2_note">・正誤<span class="d-none d-sm-inline">判定</span>の〇は正解、×は不正解、Nは無回答を表しています。</div>
          <div class="RD_section_2_note">・正答率は、全国を基準としています。</div>
          <div class="RD_section_2_note">・正答率は「正解数÷回答数」にて計算しています（無回答は除外）。</div>
        </div>

      </div><!-- row RD_section_2_1 -->

      <div class="row RD_section_2_2">

        <div class="col-12 RD_section_2_result_tables_wrapper">

          <div class="RD_section_2_result_table_wrapper">
            <div class="RD_section_2_result_table_caption">■{{ scExStudentExamPartSet.t2_is_english ? '英語' : '言語' }}</div>
            <table class="RD_section_2_result_table">
              <template v-for="(rowNo, rowIdx) in section22RowCount">
                <tr class="">
                  <td>問題No.</td>
                  <td v-for="(colNo, colIdx) in section22ColCounts[rowIdx]" :key="`row_${rowNo}_col_${colNo}`" class="">
                    {{ section22DataSet.questions[section22ColMaxPerRow*rowIdx+colIdx].question_no }}
                  </td>
                </tr>
                <tr class="">
                  <td>正誤判定</td>
                  <td v-for="(colNo, colIdx) in section22ColCounts[rowIdx]" :key="`row_${rowNo}_col_${colNo}`" class="">
                    {{ section22DataSet.questions[section22ColMaxPerRow*rowIdx+colIdx].correct_mark }}
                  </td>
                </tr>
                <tr class="">
                  <td>正答率</td>
                  <td v-for="(colNo, colIdx) in section22ColCounts[rowIdx]" :key="`row_${rowNo}_col_${colNo}`" class="">
                    {{ section22DataSet.questions[section22ColMaxPerRow*rowIdx+colIdx].correct_rate }}
                  </td>
                </tr>
              </template>
            </table>
          </div><!-- RD_section_2_result_table_wrapper -->

          <div class="RD_section_2_result_table_wrapper">
            <div class="RD_section_2_result_table_caption">■計数</div>
            <table class="RD_section_2_result_table">
              <template v-for="(rowNo, rowIdx) in section23RowCount">
                <tr class="">
                  <td>問題No.</td>
                  <td v-for="(colNo, colIdx) in section23ColCounts[rowIdx]" :key="`row_${rowNo}_col_${colNo}`" class="">
                    {{ section23DataSet.questions[section23ColMaxPerRow*rowIdx+colIdx].question_no }}
                  </td>
                </tr>
                <tr class="">
                  <td>正誤判定</td>
                  <td v-for="(colNo, colIdx) in section23ColCounts[rowIdx]" :key="`row_${rowNo}_col_${colNo}`" class="">
                    {{ section23DataSet.questions[section23ColMaxPerRow*rowIdx+colIdx].correct_mark }}
                  </td>
                </tr>
                <tr class="">
                  <td>正答率</td>
                  <td v-for="(colNo, colIdx) in section23ColCounts[rowIdx]" :key="`row_${rowNo}_col_${colNo}`" class="">
                    {{ section23DataSet.questions[section23ColMaxPerRow*rowIdx+colIdx].correct_rate }}
                  </td>
                </tr>
              </template>
            </table>
          </div><!-- RD_section_2_result_table_wrapper -->

        </div><!-- col RD_section_2_result_tables_wrapper -->

      </div><!-- row RD_section_2_2 -->

    </div><!-- RD_section_2 -->

  </div><!-- RD_report_wrapper -->
</template>

<script>
import baseMixin from '../personal_report_base';
const LogHeader = 'TAMA/PersonalReport';

export default {
  mixins: [ baseMixin ],
  props: {
  },
  data() {
    console.log(`[${LogHeader}] data() CALLED`);
    return {
      resizeDebounceTimerId: null,
      section22ColMaxPerRow: 4, // xs:4, sm:5, md:8, lg:9, xl:11
      section23ColMaxPerRow: 4, // xs:4, sm:5, md:8, lg:9, xl:11
      rankMax: 10000,
      posX_0pc: 705,
      // posX_ED, posX_DC, posX_CB, posX_BA はcomputed
      posX_100pc: 1141,
      color_E: '#808cb4',
      color_D: '#3563f9',
      color_C: '#4ac11e',
      color_B: '#ffd138',
      color_A: '#d446f8',
    };
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  computed: {
    section22RowCount() {
      return this.section22DataSet ? Math.ceil(this.section22DataSet.questions.length / this.section22ColMaxPerRow) : 0;
    },
    section22ColCounts() {
      const colCount = [];
      for (let i = 0; i < this.section22RowCount; i++) {
        colCount.push(this.section22ColMaxPerRow);
      }
      if (this.section22DataSet.questions.length % this.section22ColMaxPerRow > 0) {
        colCount[this.section22RowCount - 1] = this.section22DataSet.questions.length % this.section22ColMaxPerRow;
      }
      return colCount;
    },
    section23RowCount() {
      return this.section23DataSet ? Math.ceil(this.section23DataSet.questions.length / this.section23ColMaxPerRow) : 0;
    },
    section23ColCounts() {
      const colCount = [];
      for (let i = 0; i < this.section23RowCount; i++) {
        colCount.push(this.section23ColMaxPerRow);
      }
      if (this.section23DataSet.questions.length % this.section23ColMaxPerRow > 0) {
        colCount[this.section23RowCount - 1] = this.section23DataSet.questions.length % this.section23ColMaxPerRow;
      }
      return colCount;
    },
    posX_100_0_diff() { return this.posX_100pc - this.posX_0pc },
    posX_ED() { return this.section11DataSet ? this.posX_0pc + this.posX_100_0_diff * this.section11DataSet.national_distribution.E : 753 },
    posX_DC() { return this.section11DataSet ? this.posX_ED + this.posX_100_0_diff * this.section11DataSet.national_distribution.D : 851 },
    posX_CB() { return this.section11DataSet ? this.posX_DC + this.posX_100_0_diff * this.section11DataSet.national_distribution.C : 981 },
    posX_BA() { return this.section11DataSet ? this.posX_CB + this.posX_100_0_diff * this.section11DataSet.national_distribution.B : 1100 },
    nationalDistBarPlotCoords() {
      return [
        this.getColorBarCoords(100),
      ];
    },
    barPlotCoords() {
      return [
        this.getColorBarCoords((this.rankMax - this.section11DataSet.rank) / this.rankMax * 100),
        this.getColorBarCoords((this.rankMax - this.section12DataSet.rank) / this.rankMax * 100),
        this.getColorBarCoords((this.rankMax - this.section13DataSet.rank) / this.rankMax * 100),
      ];
    },
  },
  methods: {
    onResize() {
      // debounce化している
      if (this.resizeDebounceTimerId) {
        clearTimeout(this.resizeDebounceTimerId);
      }
      this.resizeDebounceTimerId = setTimeout(() => {
        console.log(`[${LogHeader}] onResize() CALLED`);
        // FIXME 幅を狭くしていく際に正誤表が右にはみ出していくケースでは、
        // innerWidthが想定外の値（真の値＝期待する値 ではなく、はみ出した部分も含めた幅）となり、
        // ここで得られるgridClassも想定外の値（例：smを期待しているところへmdが返る、など）となる。
        // → サイズを大幅に狭めたり、トップページに戻ったりすると正常になるのでとりあえず放置する。
        const gridClass = this.getCurrentGridClass();
        switch (gridClass) {
          case 'xs':
            this.section22ColMaxPerRow = 4;
            this.section23ColMaxPerRow = 4;
            break;
          case 'sm':
            this.section22ColMaxPerRow = 5;
            this.section23ColMaxPerRow = 5;
            break;
          case 'md':
            this.section22ColMaxPerRow = 8;
            this.section23ColMaxPerRow = 8;
            break;
          case 'lg':
            this.section22ColMaxPerRow = 9;
            this.section23ColMaxPerRow = 9;
            break;
          default: // xl
            this.section22ColMaxPerRow = 11;
            this.section23ColMaxPerRow = 11;
            break;
        }
      }, 500);
    }, // onResize()
    getColorBarCoords(inversePercent) {
      const posX = this.posX_0pc + (this.posX_100pc - this.posX_0pc) * inversePercent / 100;
      const coords = [];
      if (posX <= this.posX_ED) { // Ｅランク？
        coords.push({
          x: this.posX_0pc,
          width: posX - this.posX_0pc,
          color: this.color_E,
        });
      } else { // Ｅより上のランク？
        coords.push({ x: this.posX_0pc, width: this.posX_ED - this.posX_0pc, color: this.color_E }); // Ｅランク全体分の描画情報
        if (posX <= this.posX_DC) { // Ｄランク？
          coords.push({
            x: this.posX_ED,
            width: posX - this.posX_ED,
            color: this.color_D,
          });
        } else { // Ｄより上のランク？
          coords.push({ x: this.posX_ED, width: this.posX_DC - this.posX_ED, color: this.color_D }); // Ｄランク全体分の描画情報
          if (posX <= this.posX_CB) { // Ｃランク？
            coords.push({
              x: this.posX_DC,
              width: posX - this.posX_DC,
              color: this.color_C,
            });
          } else { // Ｃより上のランク？
            coords.push({ x: this.posX_DC, width: this.posX_CB - this.posX_DC, color: this.color_C }); // Ｃランク全体分の描画情報
            if (posX <= this.posX_BA) { // Ｂランク？
              coords.push({
                x: this.posX_CB,
                width: posX - this.posX_CB,
                color: this.color_B,
              });
            } else { // Ｂより上のランク？
              coords.push({ x: this.posX_CB, width: this.posX_BA - this.posX_CB, color: this.color_B }); // Ｂランク全体分の描画情報
              if (posX <= this.posX_100pc) { // Ａランク？
                coords.push({
                  x: this.posX_BA,
                  width: posX - this.posX_BA,
                  color: this.color_A,
                });
              } else { // ありえないが念の為
                coords.push({ x: this.posX_BA, width: this.posX_100pc - this.posX_BA, color: this.color_A }); // Ａランク全体分の描画情報
              }
            }
          }
        }
      }
      console.log(`[${LogHeader}] getColorBarCoords() coords=`, coords);
      return coords;
    }, // getColorBarCoords()
  }, // methods
};
</script>

<style lang="sass" scoped>
@import "../../../sass/_base"

// ↓ 2024-02 Re-Designed by 「TAMA1 結果画面 Bootstrap 4 Grid Template - All 5 Sizes.xd」

.RD_report_wrapper
  font-family: $fontFamilyMeiryo

  // margin-top : ResultHeaderFooterComponent下端と「あなたの成績」部上端の余白に相当
  // margin-bottom : 計数の正誤表下端とFooterComponent上端の余白に相当
  +marginY(45px, 24.7px)
  +mq_min_sm
    +marginY(52px, 58.2px)
  +mq_min_md
    +marginY(51.4px, 64.2px)
  +mq_min_lg
    +marginY(51.9px, 39.6px)
  +mq_min_xl
    +marginY(41.8px, 48.8px)

  $lightgray: #E6E2E2
  $gray: #979797

  .RD_section_1

    .row.RD_section_1_1

      // X方向の余白は（いったんゼロにしたうえで）XD上の値をそのまま使用する
      // ↓ X方向の余白をいったんゼロにする
      +marginX(-20px) // car-bodyのpaddingの打消し
      [class^="col-"]
        +paddingX(0)
      // ↑
      +paddingX(0)
      +mq_min_sm
        // +paddingX(0)
      +mq_min_md
        +paddingX(25px, 29.3px)
      +mq_min_lg
        +paddingX(64.2px, 51.8px)
      +mq_min_xl
        +paddingX(90.2px, 187.8px)

      .col-12.col-md-4.RD_section_1_title_wrapper
        display: flex
        justify-content: center
        align-items: center
        +widthAndHeight(100%, 38px)
        background-color: $lightgray
        +mq_min_sm
          +widthAndHeight(100%, 38px)
        +mq_min_md
          +widthAndHeight(207.5px, 56px)
        +mq_min_lg
          +widthAndHeight(277.83px, 56px)
        +mq_min_xl
          +widthAndHeight(390px, 64px)

        .RD_section_1_title
          +fontSizeAndLineHeight(16px)
          font-weight: bold
          +mq_min_sm
            +fontSizeAndLineHeight(20px)
          +mq_min_md
            // +fontSizeAndLineHeight(20px)
          +mq_min_lg
            +fontSizeAndLineHeight(22px)
          +mq_min_xl
            +fontSizeAndLineHeight(30px)

      // .RD_section_1_title_wrapper

      .col-12.col-md-8.RD_section_1_notes_wrapper
        padding-left: 15.1px
        padding-top: 12.1px
        +mq_min_sm
          padding-left: 37.1px
          padding-top: 16.5px
        +mq_min_md
          padding-left: 39.3px
          padding-top: 7.0px // 195.41 - 188.41
        +mq_min_lg
          padding-left: 38.0px
          padding-top: 3.5px // 204.91 - 201.41
        +mq_min_xl
          padding-left: 45.0px
          padding-top: 10.0px // 225.91 - 215.91

        .RD_section_1_note
          +fontSizeAndLineHeight(11px)
          margin-bottom: 6px
          +mq_min_sm
            +fontSizeAndLineHeight(14px)
            margin-bottom: 7px
          +mq_min_md
            // +fontSizeAndLineHeight(14px)
            // margin-bottom: 7px
          +mq_min_lg
            +fontSizeAndLineHeight(16px)
            margin-bottom: 8px
          +mq_min_xl
            // +fontSizeAndLineHeight(16px)
            // margin-bottom: 8px

      // .RD_section_1_notes_wrapper

    // .RD_section_1_1

    .row.RD_section_1_2

      margin-top: 12.1px
      +mq_min_sm
        margin-top: 10.9px
      +mq_min_md
        margin-top: 30.5px
      +mq_min_lg
        margin-top: 26.5px
      +mq_min_xl
        margin-top: 12.0px

      // X方向の余白は（いったんゼロにしたうえで）XD上の値をそのまま使用する
      // ↓ X方向の余白をいったんゼロにする
      +marginX(-20px) // car-bodyのpaddingの打消し
      [class^="col-"]
        +paddingX(0)
      // ↑
      +paddingX(13.5px, 13.5px)
      +mq_min_sm
        +paddingX(43.5px, 43.5px)
      +mq_min_md
        +paddingX(25.0px, 29.3px)
      +mq_min_lg
        +paddingX(64.2px, 51.0px)
      +mq_min_xl
        +paddingX(90.2px, 252.3px)

      .col-12.col-md-4.RD_section_1_result_tables_outer_wrapper

        .row.RD_section_1_result_tables_inner_wrapper

          // X方向の余白は（いったんゼロにしたうえで）XD上の値をそのまま使用する
          // ↓ X方向の余白をいったんゼロにする
          +marginX(0) // rowのmargin(-15px)の無効化
          [class^="col-"]
            +paddingX(0)
          // ↑

          .col-6.col-md-12.RD_section_1_result_table_wrapper

            &:nth-of-type(2)
              +mq_min_sm
                padding-left: 1.0em
              +mq_min_md
                margin-top: 1.0em
                padding-left: 0
            &:nth-of-type(3)
              margin-top: 1.0em

            .RD_section_1_result_table_caption
              margin: 0 auto
              margin-bottom: 0.3em
              width: 188.31px // tableの幅（実測）
              +fontSizeAndLineHeight(12px)
              +mq_min_sm
                margin: unset
                margin-bottom: 0.5em
                width: 208.48px
                +fontSizeAndLineHeight(14px)
              +mq_min_md
                // margin: unset
                // margin-bottom: 0.5em
                // width: 208.48px
                // +fontSizeAndLineHeight(14px)
              +mq_min_lg
                // margin: unset
                // margin-bottom: 0.5em
                width: 278.81px
                +fontSizeAndLineHeight(18px)
              +mq_min_xl
                // margin: unset
                // margin-bottom: 0.5em
                width: 365.17px
                +fontSizeAndLineHeight(20px)

            .RD_section_1_result_table
              margin: 0 auto
              +mq_min_sm
                margin: unset
              +mq_min_md
                // margin: unset
              +mq_min_lg
                // margin: unset
              +mq_min_xl
                // margin: unset
              th, td
                border: 1px solid $gray
                text-align: center
              th
                background-color: $lightgray
                width: 66.09px
                +fontSizeAndLineHeight(12px)
                +paddingY(0.3em)
                font-weight: normal
                +mq_min_sm
                  width: 66.59px
                  +fontSizeAndLineHeight(14px)
                  +paddingY(0.5em)
                +mq_min_md
                  // width: 66.59px
                  // +fontSizeAndLineHeight(14px)
                  // +paddingY(0.5em)
                +mq_min_lg
                  width: 94.45px
                  +fontSizeAndLineHeight(18px)
                  // +paddingY(0.5em)
                +mq_min_xl
                  width: 144.09px
                  +fontSizeAndLineHeight(20px)
                  +paddingY(0.55em)
              td
                width: 121.24px
                +fontSizeAndLineHeight(13px)
                +mq_min_sm
                  width: 140.91px
                  +fontSizeAndLineHeight(16px)
                +mq_min_md
                  // width: 140.91px
                  // +fontSizeAndLineHeight(16px)
                +mq_min_lg
                  width: 183.38px
                  +fontSizeAndLineHeight(18px)
                +mq_min_xl
                  width: 228.0px
                  +fontSizeAndLineHeight(20px)

          // RD_section_1_result_table_wrapper

        // RD_section_1_result_tables_inner_wrapper

      // .RD_section_1_result_tables_outer_wrapper

      .col-12.col-md-8.RD_section_1_svg_wrapper
        margin-top: 25.1px
        text-align: center
        +mq_min_sm
          margin-top: 29.7px
        +mq_min_md
          margin-top: 1.3em // 62.5px
          padding-left: 1.0em
        +mq_min_lg
          margin-top: 1.6em // 60.6px
          padding-left: 2.0em
        +mq_min_xl
          margin-top: 1.6em // 50.0px
          padding-left: 4.0em

        .RD_section_1_svg
          width: 402.91px
          max-width: 100%
          +mq_min_sm
            width: 488.91px
          +mq_min_md
            // width: 488.91px
          +mq_min_lg
            width: 606.5px
          +mq_min_xl
            width: 661.5px

      // .RD_section_1_svg_wrapper

    // .RD_section_1_2

  // .RD_section_1

  .RD_section_2

    .row.RD_section_2_1

      margin-top: 15.3px
      +mq_min_sm
        margin-top: 28.1px
      +mq_min_md
        margin-top: 30.5px
      +mq_min_lg
        margin-top: 43.1px
      +mq_min_xl
        margin-top: 62.8px

      // X方向の余白は（いったんゼロにしたうえで）XD上の値をそのまま使用する
      // ↓ X方向の余白をいったんゼロにする
      +marginX(-20px) // car-bodyのpaddingの打消し
      [class^="col-"]
        +paddingX(0)
      // ↑
      +paddingX(0)
      +mq_min_sm
        // +paddingX(0)
      +mq_min_md
        +paddingX(25px, 29.3px)
      +mq_min_lg
        +paddingX(64.2px, 51.8px)
      +mq_min_xl
        +paddingX(90.2px, 187.8px)

      .col-12.col-md-4.RD_section_2_title_wrapper
        display: flex
        justify-content: center
        align-items: center
        +widthAndHeight(100%, 38px)
        background-color: $lightgray
        +mq_min_sm
          +widthAndHeight(100%, 38px)
        +mq_min_md
          +widthAndHeight(207.5px, 56px)
        +mq_min_lg
          +widthAndHeight(277.83px, 56px)
        +mq_min_xl
          +widthAndHeight(390px, 64px)

        .RD_section_2_title
          +fontSizeAndLineHeight(16px)
          font-weight: bold
          +mq_min_sm
            +fontSizeAndLineHeight(22px)
          +mq_min_md
            // +fontSizeAndLineHeight(22px)
          +mq_min_lg
            // +fontSizeAndLineHeight(22px)
          +mq_min_xl
            +fontSizeAndLineHeight(30px)

      // .RD_section_2_title_wrapper

      .col-12.col-md-8.RD_section_2_notes_wrapper
        padding-left: 15.1px
        padding-top: 8.0px
        +mq_min_sm
          padding-left: 37.1px
          padding-top: 11.9px
        +mq_min_md
          padding-left: 27.0px
          padding-top: -1.0px // 776.5 - 777.5
        +mq_min_lg
          padding-left: 48.5px
          padding-top: 5.24px // 831.74 - 826.5
        +mq_min_xl
          padding-left: 51.0px
          padding-top: 0 // 879.74 - 879.74

        .RD_section_2_note
          +fontSizeAndLineHeight(11px)
          margin-bottom: 6px
          +mq_min_sm
            +fontSizeAndLineHeight(14px)
            margin-bottom: 7px
          +mq_min_md
            // +fontSizeAndLineHeight(14px)
            // margin-bottom: 7px
          +mq_min_lg
            +fontSizeAndLineHeight(16px)
            margin-bottom: 8px
          +mq_min_xl
            // +fontSizeAndLineHeight(16px)
            // margin-bottom: 8px

      // .RD_section_2_notes_wrapper

    // .RD_section_2_1

    .row.RD_section_2_2

      margin-top: 8.0px
      +mq_min_sm
        margin-top: 17.2px
      +mq_min_md
        margin-top: 16.6px // 16.6 or 10.6
      +mq_min_lg
        margin-top: 31.0px // 31 or 9.8
      +mq_min_xl
        margin-top: 11.7px // 11.7 or 3.7

      // X方向の余白は（いったんゼロにしたうえで）XD上の値をそのまま使用する
      // ↓ X方向の余白をいったんゼロにする
      +marginX(-20px) // car-bodyのpaddingの打消し
      [class^="col-"]
        +paddingX(0)
      // ↑
      +paddingX(19.7px, 69.6px)
      +mq_min_sm
        +paddingX(44.5px, 83.4px)
      +mq_min_md
        +paddingX(25.0px, 76.3px)
      +mq_min_lg
        +paddingX(62.1px, 119.1px)
      +mq_min_xl
        +paddingX(90.2px, 252.3px)

      .col-12.RD_section_2_result_tables_wrapper

        .RD_section_2_result_table_wrapper

          &:not(:first-of-type)
            margin-top: 28.6px
            +mq_min_sm
              margin-top: 24.2px
            +mq_min_md
              margin-top: 32.2px
            +mq_min_lg
              margin-top: 31.0px
            +mq_min_xl
              margin-top: 15.8px

          .RD_section_2_result_table_caption
            margin-bottom: 0.2em

          .RD_section_2_result_table
            tr:nth-of-type(3n+1)
              background-color: $lightgray
            td
              border: 1px solid $gray
              min-width: 68.014px // 340.07 / 5
              +mq_min_sm
                min-width: 74.675px // 448.05 / 6
              +mq_min_md
                min-width: 74.082px // 666.74 / 9
              +mq_min_lg
                min-width: 84.278px // 842.78 / 10
              +mq_min_xl
                min-width: 91.458px // 1097.5 / 12
              &:first-of-type
                text-align: left
                +paddingX(0.5em)
                +paddingY(0.5em)
                +fontSizeAndLineHeight(11px)
                +mq_min_sm
                  +fontSizeAndLineHeight(12px)
                  +paddingY(0.4em)
                +mq_min_md
                  // +fontSizeAndLineHeight(12px)
                  // +paddingY(0.4em)
                +mq_min_lg
                  +fontSizeAndLineHeight(13px)
                  // +paddingY(0.4em)
                +mq_min_xl
                  +fontSizeAndLineHeight(18px)
                  +paddingY(0.25em)
              &:not(:first-of-type)
                text-align: center
                +fontSizeAndLineHeight(12px)
                +mq_min_sm
                  // +fontSizeAndLineHeight(12px)
                +mq_min_md
                  // +fontSizeAndLineHeight(12px)
                +mq_min_lg
                  +fontSizeAndLineHeight(14px)
                +mq_min_xl
                  +fontSizeAndLineHeight(18px)

        // .RD_section_2_result_table_wrapper

      // .RD_section_2_result_tables_wrapper

    // .RD_section_2_2

  // .RD_section_2

// ↑ 2024-02 Re-Designed

</style>
