<template>
  <div class="report_wrapper my-4">

    <div class="report_body">

      <!-- 2023-09現在、TGとTAMAでほぼ同一コードだが、デザイン変更に備えて共通コンポーネントではなく試験種別（ここ）に実装している -->

      <!-- API側の都合で、ここで利用するのはsection_2だけである -->

      <div class="row section_2">

        <div class="col-12 col-md-4 section_2_title_wrapper">
          <div class="section_2_title">正誤表</div>
        </div>

        <div class="col-12 col-md-8 section_2_notes_wrapper">
          <div class="section_2_note">・正誤判定の〇は正解、×は不正解、Nは無回答を表しています。</div>
        </div>

        <div class="col-md-12 section_2_result_tables_wrapper">

          <div class="section_2_result_table_wrapper">
            <table class="section_2_result_table">
              <template v-for="(rowNo, rowIdx) in section2RowCount">
                <tr class="">
                  <td>問題No.</td>
                  <td v-for="(colNo, colIdx) in section2ColCounts[rowIdx]" :key="`row_${rowNo}_col_${colNo}`" class="">
                    {{ section2DataSet.questions[section2ColMaxPerRow*rowIdx+colIdx].question_no }}
                  </td>
                </tr>
                <tr class="">
                  <td>正誤判定</td>
                  <td v-for="(colNo, colIdx) in section2ColCounts[rowIdx]" :key="`row_${rowNo}_col_${colNo}`" class="">
                    {{ section2DataSet.questions[section2ColMaxPerRow*rowIdx+colIdx].correct_mark }}
                  </td>
                </tr>
              </template>
            </table>
          </div><!-- section_2_result_table_wrapper -->

        </div><!-- section_2_result_tables_wrapper -->

      </div><!-- section_2 -->

    </div><!-- report_body -->

  </div><!-- report_wrapper -->
</template>

<script>
import baseMixin from '../personal_report_base_2';
const LogHeader = 'TG/PersonalReport';

export default {
  mixins: [ baseMixin ],
  props: {
  },
  data() {
    console.log(`[${LogHeader}] data() CALLED`);
    return {
      section2ColMaxPerRow: 11,
    };
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  computed: {
    section2RowCount() {
      return this.section2DataSet ? Math.ceil(this.section2DataSet.questions.length / this.section2ColMaxPerRow) : 0;
    },
    section2ColCounts() {
      const colCount = [];
      for (let i = 0; i < this.section2RowCount; i++) {
        colCount.push(this.section2ColMaxPerRow);
      }
      if (this.section2DataSet.questions.length % this.section2ColMaxPerRow > 0) {
        colCount[this.section2RowCount - 1] = this.section2DataSet.questions.length % this.section2ColMaxPerRow;
      }
      return colCount;
    },
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
@import "../../../sass/_base"

.report_wrapper
  .report_body
    $lightgray: #E6E2E2
    $gray: #939393

    .section_2
      padding: 1.0rem 1.0rem

      .section_2_title_wrapper
        .section_2_title
          padding: 0.5rem 0
          background-color: $lightgray
          text-align: center
          font-size: large
          font-weight: bold

      .section_2_notes_wrapper
        display: flex
        align-items: flex-end
        +mq(min_768)
          padding-left: 2rem
        .section_2_note
          padding-left: 1em
          text-indent: -1em
          font-size: small

      .section_2_result_tables_wrapper
        .section_2_result_table_wrapper
          margin: 1.5rem 0
          overflow-x: auto
          .section_2_result_table
            tr:nth-of-type(2n+1)
              background-color: $lightgray
            td
              border: 1px solid $gray
              &:first-of-type
                text-align: center
                +mq(min_768)
                  width: 6.0rem
                  padding-left: 0.5rem
                  text-align: left
              &:not(:first-of-type)
                text-align: center
                +mq(min_768)
                  width: 4.8rem

</style>
