<template>
  <div v-if="hasParams">
    <div v-if="isDebug" class="debug_str">TAMA1 Ａタイプ 検査１ p34</div>

    <!-- ↓ 画面レイアウトタイプＩ ※choice_tableのような長いコードを含め、同一レイアウトでは同一コード -->

    <QuestionLayoutI :isDebug="isDebug" :canEdit="canEdit" :showDescription="showDescription"
                      :params="params" :allPageParamsSet="allPageParamsSet"
                      :questionIds="questionIds" :descQuestionIds="descQuestionIds" :descQuestionOrgPages="descQuestionOrgPages"
                      :descAnswers="showDescription? getDescAnswersStrArray(questionIds, choiceValues): []"
                      :descDescAnswers="showDescription? getDescAnswersStrArray(descQuestionIds, choiceValues): []"
    >

      <template v-slot:page_s01>
        <MyTextBox :canEdit="canEdit" v-model="params.s01" />
      </template>

      <template v-slot:page_s02>
        <MyTextBox :isTextarea="true" :canEdit="canEdit" v-model="params.s02" />
      </template>

      <template v-for="questionId in questionIds" v-slot:[`q${params[questionId].q_no}_question`]>
        <MyTextBox :canEdit="canEdit" v-model="params[questionId].question" />
      </template>

      <template v-for="(questionId, questionIdx) in questionIds" v-slot:[`q${params[questionId].q_no}_choice_table`]>
        <div class="choice_table_cell" v-for="(choiceValue, choiceIdx) in choiceValues[questionIdx]" :key="choiceValue">
          <input type="checkbox" :id="`${questionId}_${choiceValue}`" class="choice_checkbox" :value="choiceValue" :disabled="!canAnswer"
                  v-model="params[questionId].answer"
                  :ref="`checkbox_q${questionId}`"
                  @click="onClickCheckbox(params[questionId], $refs[`checkbox_q${questionId}`], $event)"
                  @change="sortCheckboxAnswers(params[questionId])"
          />
          <label :for="`${questionId}_${choiceValue}`" class="choice_value_wrapper choice_checkbox_placeholder has_value">
            <div class="choice_value right_margin">{{ choiceValue }}{{ params[questionId].choices[choiceIdx] ? choiceDelimiter : '' }}</div>
          </label>
          <label :for="`${questionId}_${choiceValue}`" class="choice_caption_wrapper">
            <MyTextBox class="choice_caption" :canEdit="canEdit" v-model="params[questionId].choices[choiceIdx]" />
          </label>
        </div>
      </template>

      <template v-slot:desc_choice_table>
        <div class="desc_choice_table_cell my-2" v-for="(choiceValue, choiceIdx) in choiceValues[0]" :key="`desc_choice_${choiceValue}`">
          {{ choiceValue }}{{ params[questionIds[0]].choices[choiceIdx] ? `${choiceDelimiter}${params[questionIds[0]].choices[choiceIdx]}` : '' }}
        </div>
      </template>

      <template v-for="questionId in questionIds" v-slot:[`q${params[questionId].q_no}_description`]>
        <template v-if="showDescription">
          <MyTextBox :isTextarea="true" :canEdit="canEdit" v-model="params[questionId].description" />
        </template>
      </template>

    </QuestionLayoutI>

    <!-- ↑ 画面レイアウトタイプＩ -->

  </div>
</template>

<script>
import commonMixin from '../../../common_page';
const LogHeader = 'TAMA1/Ａタイプ/検査１/p34';

export default {
  mixins: [
    commonMixin,
  ],
  data() {
    // console.log(`[${LogHeader}] data() CALLED`);
    return { // paramsを解析して自動生成することも可能だが、パフォーマンスと可読性を考慮してハードコーディングとした
      questionIds: [ 'q034' ],
      descQuestionIds: [ ], // for desc
      descQuestionOrgPages: [ ], // for desc
      choiceValues: [
        [ 'A', 'B', 'C', ],
      ],
    };
  },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  // mounted() {
  //   console.log(`[${LogHeader}] mounted() CALLED`);
  // },
  activated() {
    console.log(`[${LogHeader}] activated() CALLED`);
  },
  deactivated() {
    console.log(`[${LogHeader}] deactivated() CALLED`);
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
</style>
