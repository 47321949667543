const LogHeader = 'examination_parts/common_part';

export default {
  model: {
    prop: 'params', // all_page_params_set
    event: 'input',
  },
  props: {
    params: { default: null }, // all_page_params_set
    canEdit: { default: false }, // my_text_boxの入力モード(isEditing=true)遷移可／不可を表すが、管理画面／受験画面のどちらから呼ばれたかの判定にも用いている
    showDescription: { default: false },
    examinationPart: { default: null },
    currentPageNo: { default: null }, // 1-
    examPartRemainingSec: { default: null }, // Result.vueからは渡されない
    isDebug: { default: false },
  },
  data() {
    console.log(`[${LogHeader}] data() CALLED`);
    return {
      // ここと各 検査〇.vue のdata()の内容はマージされる（重複があるとvue側が優先）
      question_count: 0, // 問題数
      answered_count: 0, // 回答済み数
    };
  },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
    this.analyzeAllPageParamsSet();
  },
  computed: {
    isAvailable() { return this.$store.getters['status/isAvailable']; },
    isEditPage() { return this.canEdit },
    isExamPage() { return !this.canEdit && !this.showDescription },
    isResultPage() { return !this.canEdit && this.showDescription },
    currentPageComponent() { return (this.currentPageNo&&this.examinationPart)? this.examinationPart.page_component_names[this.currentPageNo-1]: null; },
    currentPageName() { return (this.currentPageNo&&this.examinationPart)? this.examinationPart.page_names[this.currentPageNo-1]: null; },
    allPageParamsSet() { return this.examinationPart? this.examinationPart.all_page_params_set: null; },
    currentPageParams: {
      get() {
        return this.allPageParamsSet[this.currentPageName];
      },
      set(newValue) { // 子コンポーネントで変更が発生したが為に呼ばれるので変更チェックは不要
        // console.log(`[${LogHeader}] pageParams(${this.currentPageName}) changed -> emitting(input)`, newValue); // デバッグ用（本番コメントアウト）

        // const emittingObj = {
        //   p01: this.currentPageName=='p01'? newValue: this.params.p01,
        //   p02: this.currentPageName=='p02'? newValue: this.params.p02,
        //   p03: this.currentPageName=='p03'? newValue: this.params.p03,
        //   ...
        // };
        // ↑ 【参考】旧コード（各 検査〇.vue に実装していた）の一部
        // ↓ 新コード（各 検査〇.vue への個別実装をやめて、ここに共通で置くことにした）
        const emittingObj = {};
        // console.log(this.examinationPart.page_names); // デバッグ用（本番コメントアウト）
        for (let pageName of this.examinationPart.page_names) {
          emittingObj[pageName] = (pageName == this.currentPageName) ? newValue: this.params[pageName];
        }

        // 回答済み数の更新
        this.analyzeAllPageParamsSet();

        console.log(`[${LogHeader}] pageParams(${this.currentPageName}) changed -> emitting(input)`, emittingObj);
        this.$emit('input', emittingObj);
      } // set()
    }, // currentPageParams()
  }, // computed
  methods: {
    analyzeAllPageParamsSet() {
      console.log(`[${LogHeader}] analyzeAllPageParamsSet() START`);
      if (!this.allPageParamsSet) { return; }
      this.question_count =  0;
      this.answered_count =  0;
      for (let pageKey/* p01, p02, ... */ in this.allPageParamsSet) {
        const pageParams = this.allPageParamsSet[pageKey];
        for (let questionKey/* q001, q002, ... *//* s01等も混ざるので注意 */ in pageParams) {
          if (questionKey.match(/^q[0-9]+$/)) {
            const questionParams = pageParams[questionKey];
            const answer = questionParams.answer ?? [];
            // console.log(`[${pageKey}][${questionKey}]=[${answer}]`); // デバッグ用（本番コメントアウト）
            this.question_count++;
            if (answer.some((v) => v !== '' && v !== null && v !== undefined)) { // 回答あり？
              this.answered_count++;
            }
          } // if
        } // for(questionKey)
      } // for(pageKey)
      console.log(`[${LogHeader}] analyzeAllPageParamsSet() END answered_count/question_count=[${this.answered_count}]/[${this.question_count}]`);
    }, // analyzeAllPageParamsSet()
    canMovePage() { // 元々、各 検査〇.vue に実装していたが、共通化のためここに移動した
      console.log(`[${LogHeader}] canMovePage() CALLED`);
      if (typeof this.$refs.refPageComponent.canMovePage === 'function') {
        return this.$refs.refPageComponent.canMovePage();
      }
      return true;
    },
    onMovePage(pageNo) {
      console.log(`[${LogHeader}] onMovePage(${pageNo}) CALLED -> emitting(move-page, ${pageNo})`);
      this.$emit('move-page', pageNo);
    },
    onMoveNextPage() {
      console.log(`[${LogHeader}] onMoveNextPage() CALLED -> emitting(move-next-page)`);
      this.$emit('move-next-page');
    },
    onMovePrevPage() {
      console.log(`[${LogHeader}] onMovePrevPage() CALLED -> emitting(move-prev-page)`);
      this.$emit('move-prev-page');
    },
    onFinishExamPart() {
      console.log(`[${LogHeader}] onFinishExamPart() CALLED -> emitting(finish-exam-part)`);
      this.$emit('finish-exam-part');
    },
  }, // methods
}
