<template>
  <div v-if="isAvailable&&examinationPart&&currentPageComponent">
    <div v-if="isDebug" class="debug_str">TAMA1 Ｃタイプ 検査１</div>

    <div class="row" :class="{ 'mt-3': isEditPage||isResultPage||isDebug }">
      <div class="col-12">
        <ExamInternalHeader :isDebug="isDebug"
                            :examinationPart="examinationPart" :currentPageNo="currentPageNo" :canEdit="canEdit" :showDescription="showDescription"
                            @move-page="onMovePage"
        />
      </div>
    </div>

    <div class="row" :class="{ 'mt-4': isEditPage||isResultPage||isDebug }">
      <div class="col-12" id="examination_parts_wrapper">
        <div v-if="isDebug" class="debug_str">ページのコンポーネント（{{ currentPageComponent }}）</div>
        <keep-alive>
          <component :is="currentPageComponent" ref="refPageComponent" :isDebug="isDebug"
                      :examinationPart="examinationPart" :canEdit="canEdit" :showDescription="showDescription"
                      choiceDelimiter="" :showLongAnswer="false"
                      v-model="currentPageParams"
          />
        </keep-alive>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <ExamInternalFooter :isDebug="isDebug"
                            :canEdit="canEdit" :showDescription="showDescription"
                            :examinationPart="examinationPart" :currentPageNo="currentPageNo" :examPartRemainingSec="examPartRemainingSec"
                            :questionCount="question_count" :answeredCount="answered_count"
                            @move-page="onMovePage" @move-next-page="onMoveNextPage" @move-prev-page="onMovePrevPage"
                            @finish-exam-part="onFinishExamPart"
        />
      </div>
    </div>

  </div>
</template>

<script>
import commonMixin from '../../common_exam_part';
import ExamInternalHeader from '../../TAMA/ExamInternalHeader';
import ExamInternalFooter from '../../TAMA/ExamInternalFooter';
// ↓ 本コンポーネントは以下のコンポーネントから呼ばれる
// admin/views/master/examination_parts/Edit.vue
// student/views/Exam.vue
// student/views/Result.vue
// ↑
// ↓ 配下の全ページをimportする ※ components への記載も忘れないこと！！
// ※ ページ部(_p01など)を除くコンポーネント名は examination_parts.main_component_name に定義されている
import tama1_c_t1_p01 from './検査１/p01';
import tama1_c_t1_p02 from './検査１/p02';
import tama1_c_t1_p03 from './検査１/p03';
import tama1_c_t1_p04 from './検査１/p04';
import tama1_c_t1_p05 from './検査１/p05';
import tama1_c_t1_p06 from './検査１/p06';
import tama1_c_t1_p07 from './検査１/p07';
import tama1_c_t1_p08 from './検査１/p08';
import tama1_c_t1_p09 from './検査１/p09';
import tama1_c_t1_p10 from './検査１/p10';
import tama1_c_t1_p11 from './検査１/p11';
import tama1_c_t1_p12 from './検査１/p12';
import tama1_c_t1_p13 from './検査１/p13';
import tama1_c_t1_p14 from './検査１/p14';
import tama1_c_t1_p15 from './検査１/p15';
import tama1_c_t1_p16 from './検査１/p16';
import tama1_c_t1_p17 from './検査１/p17';
import tama1_c_t1_p18 from './検査１/p18';
import tama1_c_t1_p19 from './検査１/p19';
import tama1_c_t1_p20 from './検査１/p20';
import tama1_c_t1_p21 from './検査１/p21';
import tama1_c_t1_p22 from './検査１/p22';
import tama1_c_t1_p23 from './検査１/p23';
import tama1_c_t1_p24 from './検査１/p24';
// ↑
const LogHeader = 'TAMA1/Ｃタイプ/検査１';

export default {
  mixins: [
    commonMixin,
  ],
  components: {
    ExamInternalHeader,
    ExamInternalFooter,
    tama1_c_t1_p01,
    tama1_c_t1_p02,
    tama1_c_t1_p03,
    tama1_c_t1_p04,
    tama1_c_t1_p05,
    tama1_c_t1_p06,
    tama1_c_t1_p07,
    tama1_c_t1_p08,
    tama1_c_t1_p09,
    tama1_c_t1_p10,
    tama1_c_t1_p11,
    tama1_c_t1_p12,
    tama1_c_t1_p13,
    tama1_c_t1_p14,
    tama1_c_t1_p15,
    tama1_c_t1_p16,
    tama1_c_t1_p17,
    tama1_c_t1_p18,
    tama1_c_t1_p19,
    tama1_c_t1_p20,
    tama1_c_t1_p21,
    tama1_c_t1_p22,
    tama1_c_t1_p23,
    tama1_c_t1_p24,
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  activated() {
    console.log(`[${LogHeader}] activated() CALLED`);
  },
  deactivated() {
    console.log(`[${LogHeader}] deactivated() CALLED`);
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
</style>
